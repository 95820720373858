import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { usePagination } from '@/commons/hooks/pagination/use-pagination'
import { PaginationStateKeys } from '@/commons/stores/pagination/pagination'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'titulo',
      label: 'Título',
      sortable: true,
    },

    {
      key: 'cliente.razao_social',
      label: 'Cliente',
      sortable: true,
    },
    {
      key: 'registros_count',
      label: 'Registros',
      class: 'text-center',
    },

    {
      key: 'Ações',
      class: 'text-center',
    },
  ]

  const {
    perPage,
    totalItens,
    currentPage,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    sortBy,
  } = usePagination({
    id: PaginationStateKeys.GRID_OBRAS,
    sortBy: 'created_at',
    isSortDirDesc: true,
  })

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-obras/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao criar a obra',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteItem = (id) => {
    store.dispatch('app-obras/deleteItem', id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Obra excluída com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      refetchData()
    })
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
  }
}
