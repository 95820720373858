import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { isEmpty } from 'lodash'

/**
 * Hook para gerenciar a paginação, ordenação e filtros, com suporte a persistência dos dados no store do vuex.
 *
 * @param {Object} [data]
 * - Configurações para personalizar a lógica de paginação e ordenação.
 *
 * @param {string} [data.id]
 * - Identificador único para a tabela. Se fornecido, as configurações de paginação serão salvas
 *  no armazenamento local com esse id. Caso contrário, as configurações não serão salvas.
 *
 * @param {Object} [data.searchQuery]
 * - Objeto representando os estados reativos para parâmetros de busca, paginação e ordenação.
 *
 * @param {number} [data.totalItems]
 * - Total de itens disponíveis para exibição na paginação.
 *
 * @param {number} [data.perPage]
 * - Número de itens exibidos por página. Define o valor inicial da configuração.
 *
 * @param {number} [data.currentPage]
 * - Número da página atual. Se não informado, o valor padrão será utilizado.
 *
 * @param {string|null} [data.sortBy]
 * - Campo utilizado para ordenar os itens. Se não informado, nenhum campo será utilizado para ordenação por padrão.
 *
 * @param {boolean} [data.isSortDirDesc]
 * - Determina a direção da ordenação. Se 'true', a ordenação será descendente, caso contrário será ascendente.
 *
 * @param {Array<number>} [data.perPageOptions]
 * - Array contendo diferentes opções de quantidade de itens por página.
 *
 * @returns {Object} - Objeto contendo os dados atuais de paginação e funções para manipulação dessas configurações.
 */
export function usePagination(data) {
  /**
   * Retorna o estado atual da paginação, baseado no identificador único fornecido.
   *
   * @param {string} id - Identificador único da paginação, usado para acessar o estado específico da paginação.
   *
   * @returns {Object} - O estado atual da paginação, que contém informações sobre paginação e ordenação.
   */
  const getActualState = (id) =>
    store.getters['paginationStore/getPaginationState'][id]

  // Obtém o estado atual da paginação, se a identificação for fornecida.
  const _paginationState = getActualState(data.id)

  /**
   * Estado reativo para o termo de busca utilizado na paginação.
   *
   * Este valor representa o texto ou termo usado para filtrar os itens exibidos na paginação.
   *
   * @type {typeof import('vue').Ref<string>}
   */
  const searchQuery = ref(
    _paginationState?.searchQuery ?? data?.searchQuery ?? '',
  )

  /**
   * Estado reativo para o total de itens disponíveis na paginação.
   *
   * Representa a quantidade total de itens que a paginação pode exibir, baseado nos filtros aplicados.
   *
   * @type {typeof import('vue').Ref<number>}
   */
  const totalItens = ref(_paginationState?.totalItens ?? 0)

  /**
   * Estado reativo para o número de itens exibidos por página na paginação.
   *
   * Esse valor controla quantos itens são exibidos a cada vez. O valor padrão é 10, mas pode ser alterado.
   *
   * @type {typeof import('vue').Ref<number>}
   */
  const perPage = ref(_paginationState?.perPage ?? data?.perPage ?? 10)

  /**
   * Estado reativo para o número da página atual exibida na paginação.
   *
   * Esse valor define qual página está sendo visualizada. O valor padrão é 1, mas pode ser alterado.
   *
   * @type {typeof import('vue').Ref<number>}
   */
  const currentPage = ref(
    _paginationState?.currentPage ?? data?.currentPage ?? 1,
  )

  /**
   * Estado reativo para o campo utilizado para ordenar os itens na paginação.
   *
   * Este valor define qual campo será utilizado para ordenar os itens, podendo ser alterado a qualquer momento.
   *
   * @type {typeof import('vue').Ref<string|null>}
   */
  const sortBy = ref(_paginationState?.sortBy ?? data?.sortBy ?? null)

  /**
   * Estado reativo para a direção da ordenação (ascendente ou descendente).
   *
   * Se 'true', a ordenação será descendente; se 'false', ascendente.
   * O valor padrão pode ser 'null' se não for especificado.
   *
   * @type {typeof import('vue').Ref<boolean|null>}
   */
  const isSortDirDesc = ref(
    _paginationState?.isSortDirDesc ?? data?.isSortDirDesc ?? null,
  )

  /**
   * Estado reativo para as opções de quantidades de itens por página disponíveis na paginação.
   *
   * Esse valor é um array que define as diferentes opções de número de itens por página que o usuário pode selecionar.
   * O valor padrão é '[10, 25, 50, 100]'.
   *
   * @type {typeof import('vue').Ref<number[]>}
   */
  const perPageOptions = ref(
    _paginationState?.perPageOptions ?? [10, 25, 50, 100],
  )

  // Configura observação dos estados para persistir alterações, se um identificador de paginação for fornecido.
  if (!isEmpty(data?.id)) {
    const watchSources = [
      searchQuery,
      totalItens,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      perPageOptions,
    ]

    watch(watchSources, (state) => {
      const [
        searchQuery,
        totalItens,
        perPage,
        currentPage,
        sortBy,
        isSortDirDesc,
        perPageOptions,
      ] = state

      store.commit('paginationStore/setPaginationState', {
        paginationId: data.id,
        data: {
          searchQuery,
          totalItens,
          perPage,
          currentPage,
          sortBy,
          isSortDirDesc,
          perPageOptions,
        },
      })
    })
  }

  /**
   * Atualiza o termo de busca (searchQuery) utilizado na paginação.
   *
   * Essa função é chamada sempre que o termo de busca é alterado pelo usuário.
   *
   * @param {string} query - Novo valor para o termo de busca.
   */
  const updateSearchQuery = (query) => {
    searchQuery.value = query
  }

  /**
   * Atualiza o número de itens exibidos por página (perPage).
   *
   * Essa função é chamada para modificar a quantidade de itens exibidos por página.
   *
   * @param {number} itemsPerPage - Novo valor para o número de itens por página.
   */
  const updatePerPage = (itemsPerPage) => {
    perPage.value = itemsPerPage
  }

  /**
   * Atualiza o número da página atual (currentPage).
   *
   * Essa função é chamada quando o usuário navega entre as páginas da paginação.
   *
   * @param {number} page - Novo número da página a ser exibida.
   */
  const updateCurrentPage = (page) => {
    currentPage.value = page
  }

  /**
   * Atualiza o campo de ordenação (sortBy).
   *
   * Essa função é chamada quando o usuário seleciona um novo campo para ordenar os itens.
   *
   * @param {string} field - Novo campo a ser usado para ordenar os itens.
   */
  const updateSortBy = (field) => {
    sortBy.value = field
  }

  /**
   * Alterna a direção da ordenação (ascendente ou descendente).
   *
   * Essa função inverte a direção de ordenação, passando de ascendente para descendente e vice-versa.
   */
  const toggleSortDirection = () => {
    isSortDirDesc.value = !isSortDirDesc.value
  }

  return {
    searchQuery,
    perPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    perPageOptions,
    totalItens,
    updateSearchQuery,
    updatePerPage,
    updateCurrentPage,
    updateSortBy,
    toggleSortDirection,
    getActualState,
  }
}
