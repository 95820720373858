export class PaginationStateKeys {
  /**
   * Identificador da grid de clientes que será usado no state global.
   *
   * @type {string}
   */
  static GRID_CLIENTES = 'grid-clientes'

  /**
   * Identificador da grid de obras que será usado no state global.
   *
   * @type {string}
   */
  static GRID_OBRAS = 'grid-obras'

  /**
   * Identificador da grid de projetos que será usado no state global.
   *
   * @type {string}
   */
  static GRID_PROJETOS = 'grid-projetos'

  /**
   * Identificador da grid de propostas que será usado no state global.
   *
   * @type {string}
   */
  static GRID_PROPOSTAS = 'grid-propostas'

   /**
   * Identificador da grid de produtos que será usado no state global.
   *
   * @type {string}
   */
  static GRID_PRODUTOS  = 'grid-produtos'
}
